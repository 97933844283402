import { contactConstants } from '../_constants/contact.constants';

const initialState = { 
  isSendingEmail: false, 
  isEmailSent: false, 
};

export const contact = (state = initialState, action) => {
  switch (action.type) {
    case contactConstants.SEND_EMAIL_REQUEST:
      return {
        isSendingEmail: true,
        isEmailSent: false, 
        user: null
      };
    case contactConstants.SEND_EMAIL_SUCCESS:
      return {
        isSendingEmail: false, 
        isEmailSent: true, 
      };
    case contactConstants.SEND_EMAIL_FAILURE:
      return {
        isSendingEmail: false,
        isEmailSent: false, 
        message: action.message
      };
    case contactConstants.SEND_EMAIL_RESET:
      return {
        isSendingEmail: false,
        isEmailSent: false, 
        message: null
      };
    default:
      return state
  }
}