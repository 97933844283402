import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem } from "react-bootstrap";

import { userActions } from '../_actions/user.actions';

import './Header.css';

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  toggleNav = expanded => this.setState({ navExpanded: expanded });
  openNav = () => this.setState({ navExpanded: true });
  closeNav = () => this.setState({ navExpanded: false });
  
  handleLogout = event => {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }
  
  render() { 
    return (
      <Navbar id="nav-top" fixed="top" bg="dark" variant="dark" expand="md" className={(this.props.placeholder) ? 'nav-top placeholder' : 'nav-top'} onToggle={this.toggleNav} expanded={this.state.navExpanded}>
        <div className="container">
          <Navbar.Brand as="div">
            <Link to="/" onClick={this.closeNav}>minml.net</Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" as="div" onClick={this.openNav} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav hidden={!this.props.placeholder}>
              <NavItem onClick={this.closeNav}>Projects</NavItem>
              <NavItem onClick={this.closeNav}>About</NavItem>
              <NavItem onClick={this.closeNav}>Contact</NavItem>
              <NavItem onClick={this.closeNav}>Login</NavItem>
            </Nav>
            <Nav hidden={this.props.placeholder}>
              {this.props.isAuthenticated &&
                <NavLink to='/admin' className="nav-item" activeClassName="active" onClick={this.closeNav}>Admin</NavLink>
              }
              <NavLink to="/projects" className="nav-item" activeClassName="active" onClick={this.closeNav}>Projects</NavLink>
              <NavLink to="/about" className="nav-item" activeClassName="active" onClick={this.closeNav}>About</NavLink>
              <NavLink to="/contact" className="nav-item" activeClassName="active" onClick={this.closeNav}>Contact</NavLink>
              {!this.props.isAuthenticated
                ? <NavLink to='/login' className="nav-item" activeClassName="active" onClick={this.closeNav}>Login</NavLink>
                : <NavItem onClick={this.handleLogout}>Logout</NavItem>
              }
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  const { placeholder, authentication } = state;
  const {isAuthenticated, user} = authentication;
  return {
    placeholder,
    isAuthenticated,
    user
  };
}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header }; 
