import React from 'react';
import { Navbar } from "react-bootstrap";
import './Footer.css';

const Footer = () => {
  return (
    <Navbar fixed="bottom" bg="dark" variant="dark" expand="md" className="nav-bottom">
      <div className="container">
        <div className="footer">
          &copy; { (new Date()).getFullYear() } minml.net. All rights reserved.
        </div>
      </div>
    </Navbar>

  );
};

export default Footer;