import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { contact } from './contact.reducer';
import { placeholder } from './placeholder.reducer';
import { projects } from './projects.reducer';
// import { registration } from './registration.reducer';
import { users } from './users.reducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  alert,
  authentication,
  contact,
  placeholder,
  projects,
  // registration,
  users,
});

export default rootReducer;
