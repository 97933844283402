export const projectConstants = {
  LIST_REQUEST: 'PROJECTS_LIST_REQUEST',
  LIST_SUCCESS: 'PROJECTS_LIST_SUCCESS',
  LIST_FAILURE: 'PROJECTS_LIST_FAILURE',

  GET_REQUEST: 'PROJECTS_GET_REQUEST',
  GET_SUCCESS: 'PROJECTS_GET_SUCCESS',
  GET_FAILURE: 'PROJECTS_GET_FAILURE',

  CREATE_REQUEST: 'PROJECTS_CREATE_REQUEST',
  CREATE_SUCCESS: 'PROJECTS_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROJECTS_CREATE_FAILURE',

  UPDATE_REQUEST: 'PROJECTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROJECTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROJECTS_UPDATE_FAILURE',

  DELETE_REQUEST: 'PROJECTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROJECTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROJECTS_DELETE_FAILURE'    
};

