import { userConstants } from '../_constants/user.constants';

const initialState = { 
  isAuthenticating: true, 
  isAuthenticated: false, 
  isSendingCode: false,
  isCodeSent: false,
  isResettingPassword: false,
  isPasswordReset: false,
  user: null 
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        isAuthenticating: true,
        isAuthenticated: false, 
        user: null
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        isAuthenticating: false, 
        isAuthenticated: true, 
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        isAuthenticating: false,
        isAuthenticated: false, 
        user: null,
        message: action.message
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        isAuthenticating: true,
        isAuthenticated: false, 
        user: null
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        isAuthenticating: false, 
        isAuthenticated: false, 
        user: action.user
      };
    case userConstants.LOGOUT_FAILURE:
      return {
        isAuthenticating: false,
        isAuthenticated: false, 
        user: null,
        message: action.message
      };
    case userConstants.CURRENT_SESSION_REQUEST:
      return {
        isAuthenticating: true,
        isAuthenticated: false, 
        user: null
      };
    case userConstants.CURRENT_SESSION_SUCCESS:
      return {
        isAuthenticating: false, 
        isAuthenticated: true, 
        user: action.user
      };
    case userConstants.CURRENT_SESSION_FAILURE:
      return {
        isAuthenticating: false,
        isAuthenticated: false, 
        user: null,
        message: action.message
      };
    case userConstants.FORGOT_PASSWORD_CODE_REQUEST:
      return {
        isSendingCode: true,
        isCodeSent: false,
        isResettingPassword: false,
        isPasswordReset: false,
      };
    case userConstants.FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        isSendingCode: false,
        isCodeSent: true,
        isResettingPassword: false,
        isPasswordReset: false,
      };
    case userConstants.FORGOT_PASSWORD_CODE_FAILURE:
      return {
        isSendingCode: false,
        isCodeSent: false,
        isResettingPassword: false,
        isPasswordReset: false,
        message: action.message
      };
    case userConstants.FORGOT_PASSWORD_UPDATE_REQUEST:
      return {
        isSendingCode: false,
        isCodeSent: false,
        isResettingPassword: true,
        isPasswordReset: false,
      };
    case userConstants.FORGOT_PASSWORD_UPDATE_SUCCESS:
      return {
        isSendingCode: false,
        isCodeSent: false,
        isResettingPassword: false,
        isPasswordReset: true,
      };
    case userConstants.FORGOT_PASSWORD_UPDATE_FAILURE:
      return {
        isSendingCode: false,
        isCodeSent: false,
        isResettingPassword: false,
        isPasswordReset: false,
        message: action.message
      };
      case userConstants.FORGOT_PASSWORD_RESET:
        return {
          isSendingCode: false,
          isCodeSent: false,
          isResettingPassword: false,
          isPasswordReset: false,
        };
      default:
      return state
  }
}