export const userConstants = {
  // REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  // REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  // REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  
  LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

  CURRENT_SESSION_REQUEST: 'USERS_CURRENT_SESSION_REQUEST',
  CURRENT_SESSION_SUCCESS: 'USERS_CURRENT_SESSION_SUCCESS',
  CURRENT_SESSION_FAILURE: 'USERS_CURRENT_SESSION_FAILURE',

  FORGOT_PASSWORD_CODE_REQUEST: 'USERS_FORGOT_PASSWORD_CODE_REQUEST',
  FORGOT_PASSWORD_CODE_SUCCESS: 'USERS_FORGOT_PASSWORD_CODE_SUCCESS',
  FORGOT_PASSWORD_CODE_FAILURE: 'USERS_FORGOT_PASSWORD_CODE_FAILURE',

  FORGOT_PASSWORD_UPDATE_REQUEST: 'USERS_FORGOT_PASSWORD_UPDATE_REQUEST',
  FORGOT_PASSWORD_UPDATE_SUCCESS: 'USERS_FORGOT_PASSWORD_UPDATE_SUCCESS',
  FORGOT_PASSWORD_UPDATE_FAILURE: 'USERS_FORGOT_PASSWORD_UPDATE_FAILURE',

  FORGOT_PASSWORD_RESET: 'USERS_FORGOT_PASSWORD_RESET',

  LIST_REQUEST: 'USERS_LIST_REQUEST',
  LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  LIST_FAILURE: 'USERS_LIST_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};
