
export const Project = {
  client: '',
  title: '',
  slug: '',
  summary: '',
  description: '',
  coverImage: '',
  screenshots: [],
  isPublished: false,
  isFeatured: false,
  createdAt: 0,
  updatedAt: 0,
}
