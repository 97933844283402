import { projectConstants } from '../_constants/project.constants';
import { removeEmpty } from '../_helpers/helper-functions';
import { Project } from '../_models/project';

const initialState = { 
  loading: false, 
  creating: false, 
  items: null, 
  item: null,
  error: null,
};

export function projects(state = initialState, action) {
  switch (action.type) {
    case projectConstants.LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case projectConstants.LIST_SUCCESS:
      const listedItems = action.result.map(item => ({...Project, ...removeEmpty(item)}));
      return {
        ...state,
        loading: false,
        items: sortItems(listedItems),
      };
    case projectConstants.LIST_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error,
      };
    case projectConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case projectConstants.GET_SUCCESS:
      const gotItem = {...Project, ...removeEmpty(action.result)};
      return {
        ...state,
        loading: false,
        item: gotItem,
      };
    case projectConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error,
      };
    case projectConstants.CREATE_REQUEST:
      return {
        ...state,
        item: action.object,
        creating: true,
      };
    case projectConstants.CREATE_SUCCESS:
      const createdItem = {...Project, ...removeEmpty(action.result)};
      const createdItems = sortItems([...state.items, createdItem]);
      return {
        ...state,
        creating: false,
        items: createdItems,
        item: createdItem,
        error: null,
      };
    case projectConstants.CREATE_FAILURE:
      return { 
        ...state,
        creating: false,
        error: action.error,
      };
    case projectConstants.UPDATE_REQUEST:
      const updatingItem = {...action.object, updating: true, updated: false};
      const updatingItems = (state.items)
        ? sortItems(state.items.map(item => item.projectId === action.object.projectId ? updatingItem : item))
        : state.items;
      return {
        ...state,
        items: updatingItems,
        item: updatingItem
      };
    case projectConstants.UPDATE_SUCCESS:
      console.log('UPDATE_SUCCESS', action.result);
      const updatedItem = {...Project, ...removeEmpty(action.result), updating: false, updated: true};
      const updatedItems = (state.items)
        ? sortItems(state.items.map(item => item.projectId === action.result.projectId ? updatedItem : item))
        : state.items;
      return {
        ...state,
        items: updatedItems,
        item: updatedItem,
        error: null,
      };
    case projectConstants.UPDATE_FAILURE:
      const unupdatedItem = {...state.item, updating: false, updated: false};
      const unupdatedItems = (state.items) 
        ? sortItems(state.items.map(item => ({...item, updating: false, updated: false})))
        : state.items;
      return { 
        ...state,
        items: unupdatedItems,
        item: unupdatedItem,
        error: action.error,
      };
    case projectConstants.DELETE_REQUEST:
      // add 'deleting:true' property to item being deleted
      const deletingItem = {...action.object, deleting: true};
      const deletingItems = (state.items)
        ? state.items.map(project => project.projectId === action.object.projectId ? deletingItem : project)
        : state.items;
      return {
        ...state,
        items: deletingItems,
        item: deletingItem
      };
    case projectConstants.DELETE_SUCCESS:
      // remove deleted project from state
      const deletedItems = (state.items)
        ? state.items.filter(project => project.projectId !== action.result.projectId)
        : state.items;
      return {
        ...state,
        items: deletedItems,
        item: null
      };
    case projectConstants.DELETE_FAILURE:
        const undeletedItem = {...state.item, deleting: false};
        const undeletedItems = (state.items)
          ? state.items.map(project => { return {...project, deleting: false}; })
          : state.items;
      return {
        ...state,
        items: undeletedItems,
        item: undeletedItem,
        error: action.error
      };
    default:
      return state;
  }
}

const sortItems = projects => {
  return projects.sort((a, b) => {
    if (typeof(a.completedAt) === 'undefined' && typeof(b.completedAt) === 'undefined') return 0;
    if (typeof(a.completedAt) === 'undefined') return -1;
    if (typeof(b.completedAt) === 'undefined') return 1;
    return new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime();
  });
}