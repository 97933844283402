
export const removeEmpty = obj => {
  Object.entries(obj).forEach(([key, val])  => (
    (val && typeof val === 'object') && removeEmpty(val)) ||
    ((val === null || val === "") && delete obj[key])
  );
  return obj;
};

export const permalink = project => {
  const permalink = (typeof(project.slug) !== 'undefined' && project.slug.length > 0) ? project.slug : project.projectId;
  return permalink;
}