import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from "react-router-dom";

import googleAnalytics from './_helpers/google-analytics';
import LoadingComponent from './components/LoadingComponent';

// import { ProjectList } from './pages/ProjectList';
// import { ProjectShow } from './pages/ProjectShow';
// import { ProjectList } from './pages/ProjectList';

// import { About } from './pages/About';
// import { Contact } from './pages/Contact';
// import { Login } from './pages/Login';
// import { ResetPassword } from './pages/ResetPassword';

// import { Admin } from './pages/admin/Admin';

// import NotFound from './pages/NotFound';

const HomeComponent = lazy(() => import('./pages/Home'));

const ProjectsListComponent = lazy(() => import('./pages/ProjectList'));
const ProjectShowComponent = lazy(() => import('./pages/ProjectShow'));

const AboutComponent = lazy(() => import('./pages/About'));
const ContactComponent = lazy(() => import('./pages/Contact'));
const LoginComponent = lazy(() => import('./pages/Login'));
const ResetPasswordComponent = lazy(() => import('./pages/ResetPassword'));

const AdminComponent = lazy(() => import('./pages/admin/Admin'));

const NotFoundComponent = lazy(() => import('./pages/NotFound'));

class Routes extends Component {

  render() {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          <Route path="/" exact component={ googleAnalytics(HomeComponent) } />
          <Route path='/projects' exact component={ googleAnalytics(ProjectsListComponent) } />
          {/* <Route path='/project/new' component={ProjectCreate} /> */}
          <Route path='/projects/:id' exact component={ googleAnalytics(ProjectShowComponent) } />
          {/* <Route path='/project/:id/edit' component={ProjectEdit} />
          <Route path='/project/:id/detele' component={ProjectDelete} /> */}
          <Route path='/about' component={ googleAnalytics(AboutComponent) } />
          <Route path='/contact' component={ googleAnalytics(ContactComponent)} />
          <Route exact path="/login" render={() => this.props.isAuthenticated ? <Redirect to="/" /> : <LoginComponent />}/>
          <Route path="/login/reset" render={() => this.props.isAuthenticated ? <Redirect to="/" /> : <ResetPasswordComponent />}/>
          <Route path="/admin" render={() => !this.props.isAuthenticated ? <Redirect to="/" /> : <AdminComponent />}/>

          <Route component={ googleAnalytics(NotFoundComponent) } />
        </Switch>
        </Suspense>
    );
  }
}


const mapStateToProps = (state) => {
  const { placeholder, authentication } = state;
  const { isAuthenticated } = authentication;
  return {
    placeholder,
    isAuthenticated
  };
}

const connectedRoutes = connect(mapStateToProps)(Routes);
export { connectedRoutes as Routes }; 
