import React, { Component } from "react";
import { connect } from 'react-redux';

import { alertActions } from './_actions/alert.actions';
import { userActions } from './_actions/user.actions';
import { history } from './_helpers/history';
import { Routes } from './Routes';
import { Header } from "./components/Header";
import Footer from "./components/Footer";

class App extends Component {

  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.currentSession())
  }
  
  render() {
    const { alert } = this.props;
    return (
      !this.props.isAuthenticated !== null &&
        <div className="App container">
          <Header />

          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }

          <Routes />
          <Footer />
        </div>
    );
  }

}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  const { isAuthenticating } = authentication;
  return {
      alert,
      isAuthenticating
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
