import React from 'react';
import ReactDom from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Amplify from "aws-amplify";

import 'react-widgets/dist/css/react-widgets.css';

import amplifyConfig from './aws-amplify.config';
import { history } from'./_helpers/history';
import { store } from'./_helpers/store';

import { App } from './App';
import './index.css';

Amplify.configure(amplifyConfig);

ReactDom.render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <App />
    </ConnectedRouter>
  </Provider>
  ,
  document.querySelector('#root')
);
