import config from '../config';
import { Auth } from "aws-amplify";

export const userService = {
  login,
  logout,
  currentSession,
  forgotPasswordCode,
  forgotPasswordUpdate,
  // register,
  list,
  getById,
  completeNewPassword,
  update,
  delete: _delete
};

async function login(email, password) {
  return Auth.signIn(email, password);
}

function logout() {
  return Auth.signOut();
}

function currentSession() {
  return Auth.currentSession();
}
function forgotPasswordCode(username) {
  return Auth.forgotPassword(username);
}
function forgotPasswordUpdate(code, username, password) {
  return Auth.forgotPasswordSubmit(username, code, password);
}

async function list() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader()
    };

    const response = await fetch(`${config.apiGateway.URL}/users`, requestOptions);
  return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader()
    };

    const response = await fetch(`${config.apiGateway.URL}/users/${id}`, requestOptions);
  return handleResponse(response);
}

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiGateway.URL}/users/register`, requestOptions).then(handleResponse);
// }

async function completeNewPassword(user, password) {
  return Auth.completeNewPassword(user, password)
}

async function update(user) {
  const requestOptions = {
      method: 'PUT',
      // headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
  };

  const response = await fetch(`${config.apiGateway.URL}/users/${user.id}`, requestOptions);
return handleResponse(response);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        // headers: authHeader()
    };

    const response = await fetch(`${config.apiGateway.URL}/users/${id}`, requestOptions);
  return handleResponse(response);
}

function handleResponse(response) {
  console.log('handleResponse', response);
    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);
    //     if (!response.ok) {
    //         if (response.status === 401) {
    //             // auto logout if 401 response returned from api
    //             logout();
    //             // location.reload(true);
    //         }

    //         const error = (data && data.message) || response.statusText;
    //         return Promise.reject(error);
    //     }

    //     return data;
    // });
}