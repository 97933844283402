import React from "react";

import "./LoadingComponent.css";

export default ({
  showSpinner = false,
  ...props
}) =>
  <div className="big-loader">
    <i hidden={!showSpinner} className="fa fa-refresh spinning"></i>
  </div>;
