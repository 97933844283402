const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "minml-net-api-dev-attachmentsbucket-3dhyhh96ep6r"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://nmwlpx72ag.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_QEEpEjbe3",
    APP_CLIENT_ID: "5vaomjta50uibiam5binv6al0e",
    IDENTITY_POOL_ID: "us-west-2:8a6ae9d3-2ffd-4818-b805-a0f72e627f55"
  },
  contact: {
    PATH: '/hello',
  },
};

const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "minml-net-api-prod-attachmentsbucket-4tad3leiszr7"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://k4ggufu38i.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_IMjqZ4Vr9",
    APP_CLIENT_ID: "3afc3jjsed1ii8dl7c6burqnth",
    IDENTITY_POOL_ID: "us-west-2:bed106b0-d78f-4a0b-a257-9e2ff5940839"
  },
  contact: {
    PATH: '/hello',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
