import { push } from 'connected-react-router';

import { userConstants } from '../_constants/user.constants';
import { userService } from '../_services/user.service';
import { alertActions } from './alert.actions';
// import { history } from '../_helpers';

const errors = {
  "LimitExceededException" : 'Sorry, you\'ve sent too many codes! Try again later, or use that last one that you received.',
  "UserNotFoundException" : 'Sorry, we couldn\'t find you. Check your spelling and try again.',
}

export const userActions = {
  login,
  logout,
  currentSession,
  forgotPasswordCode,
  forgotPasswordUpdate,
  forgotPasswordReset,
  // register,
  list,
  delete: _delete
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    userService.login(username, password)
      .then(
        result => { 
          const message = 'Welcome back! You\'ve been logged in.';
          dispatch(success(result));
          dispatch(push('/admin'));
          dispatch(alertActions.success(message));
          // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          //   userService.completeNewPassword(user, "Hycam2ma");
          // }
        },
        error => {
          dispatch(failure(error.code));
          dispatch(alertActions.error(errors[error.code] || `${error.message} [${error.code}]`));
        }
      );
  };
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(result) { return { type: userConstants.LOGIN_SUCCESS, result } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  return dispatch => {
    dispatch(request());

    userService.logout()
      .then(
        result => { 
          const message = 'You\'ve been logged out. See ya later!';
          dispatch(success(result));
          dispatch(alertActions.success(message));
        },
        error => {
          dispatch(failure(error.code));
          dispatch(alertActions.error(errors[error.code] || `${error.message} [${error.code}]`));
        }
      );
  };
  function request(user) { return { type: userConstants.LOGOUT_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGOUT_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGOUT_FAILURE, error } }
}

function currentSession() {
  return dispatch => {
    dispatch(request());
    userService.currentSession()
      .then(
        result => { 
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error.code));
        }
      );
  };
  function request() { return { type: userConstants.CURRENT_SESSION_REQUEST } }
  function success(result) { return { type: userConstants.CURRENT_SESSION_SUCCESS, result } }
  function failure(error) { return { type: userConstants.CURRENT_SESSION_FAILURE, error } }
}

function forgotPasswordCode(username) {
  return dispatch => {
    dispatch(request());
    userService.forgotPasswordCode(username)
      .then(
        result => { 
          const message = 'We\'ve sent a code to your email address. Use it to reset your password here.'
          dispatch(success(result));
          dispatch(alertActions.success(message));
        },
        error => {
          dispatch(failure(error.code));
          dispatch(alertActions.error(errors[error.code] || `${error.message} [${error.code}]`));
        }
      );
  };
  function request() { return { type: userConstants.FORGOT_PASSWORD_CODE_REQUEST } }
  function success(result) { return { type: userConstants.FORGOT_PASSWORD_CODE_SUCCESS, result } }
  function failure(error) { return { type: userConstants.FORGOT_PASSWORD_CODE_FAILURE, error } }
}

function forgotPasswordUpdate(code, username, password) {
  console.log('forgotPasswordUpdate', code, username, password);
  return dispatch => {
    dispatch(request());
    userService.forgotPasswordUpdate(code, username, password)
      .then(
        result => { 
          console.log('forgotPasswordUpdate success', result);
          const message = 'Your password has been reset.'
          dispatch(success(result));
          dispatch(alertActions.success(message));
        },
        error => {
          console.log('forgotPasswordUpdate error', error);
          dispatch(failure(error.code));
          dispatch(alertActions.error(errors[error.code] || `${error.message} [${error.code}]`));
        }
      );
  };
  function request() { return { type: userConstants.FORGOT_PASSWORD_UPDATE_REQUEST } }
  function success(result) { return { type: userConstants.FORGOT_PASSWORD_UPDATE_SUCCESS, result } }
  function failure(error) { return { type: userConstants.FORGOT_PASSWORD_UPDATE_FAILURE, error } }
}

function forgotPasswordReset() {
  return dispatch => {
    dispatch(reset());
  }
  function reset() { return { type: userConstants.FORGOT_PASSWORD_RESET } }
}

// function register(user) {
//     return dispatch => {
//         dispatch(request(user));

//         userService.register(user)
//             .then(
//                 user => { 
//                     dispatch(success());
//                     history.push('/login');
//                     dispatch(alertActions.success('Registration successful'));
//                 },
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
//     function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
// }

function list() {
  return dispatch => {
    dispatch(request());

    userService.list()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: userConstants.LIST_REQUEST } }
  function success(users) { return { type: userConstants.LIST_SUCCESS, users } }
  function failure(error) { return { type: userConstants.LIST_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id)
      .then(
        user => dispatch(success(id)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}